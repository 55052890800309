export const IsMobile = (mediaQuery = "only screen and (max-width: 760px)") => {
    return window.matchMedia(mediaQuery).matches;
};

export const IsDesktop = () => {
    return !(/Android|webOS|iPhone|Mobi/i.test(navigator.userAgent))
};

export const InjectScrollBar = (ionContent: HTMLIonContentElement) => {
    const exists = ionContent?.shadowRoot?.getElementById('injected-scroll-bar');
    if (!exists) {
        const style = document.createElement('style')
        style.id = 'injected-scroll-bar';
        style.innerHTML = '@media only screen and (min-width: 760px) {' +
            '.inner-scroll::-webkit-scrollbar {\n' +
            '   width: 4px;\n' +
            '   height: 4px;\n' +
            '}\n' +
            '.inner-scroll::-webkit-scrollbar-thumb {\n' +
            '    background: rgba(146, 148, 156, 0.7);\n' +
            '   border-radius: 20px;\n' +
            '}}'
        ionContent?.shadowRoot?.appendChild(style)
    }
}
